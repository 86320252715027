import { render, staticRenderFns } from "./UCIrvineELNReport1.vue?vue&type=template&id=6beac6ce&scoped=true&"
import script from "./UCIrvineELNReport1.vue?vue&type=script&lang=ts&"
export * from "./UCIrvineELNReport1.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6beac6ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFileInput,VForm,VRow})
